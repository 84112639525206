import React from "react";
import * as logRocket from "logrocket";

import config from "lib/config";

import SessionContext from "context/session";

type LogRocket = typeof logRocket;

let logger: Console | LogRocket = console;
let logrocketActive = false;

if (!logRocket || config.ENVIRONMENT === "development") {
  logger = console;
} else {
  logger = logRocket;
  logrocketActive = true;
  logger.init("z1ihio/team-onboarding");
}

export const LoggerContainer = ({ children }: React.PropsWithChildren<{}>) => {
  const { authenticated, currentUser } = React.useContext(SessionContext);
  const id = currentUser?.id as string;
  const email = currentUser?.email as string;

  React.useEffect(() => {
    if (logrocketActive && authenticated) {
      (logger as LogRocket).identify(id, { email });
    }
  }, [authenticated, id, email]);

  return <>{children}</>;
};

export default logger;
