import React from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

import DeskIcon from "components/Svg/Desk";
import MeetingRoomIcon from "components/Svg/MeetingRoom";
import OfficeIcon from "components/Svg/Office";
import GuestsIcon from "components/Svg/Guests";
import IconCheckbox from "components/IconCheckbox";
import Button from "components/Button";
import SkipButton from "components/Button/Skip";
import { DiscreteValue } from "components/DiscreteValueSlider";

import logger from "lib/log";
import routes from "lib/routes";
import * as browserStorage from "lib/browserStorage";

import { breakpoints } from "global-styles/lib/media";

import SessionContext from "context/session";
import NotificationContext from "context/notification";

import * as StyledPage from "pages/styles";
import * as Styled from "./styles";

type ReservationFields = {
  allowBookings: boolean;
  allowHourlyBookings: boolean;
  allowOfficeBookings: boolean;
  allowGuests: boolean;
  globalLimit: number;
};

const defaultGlobalLimit = 2000;
const sliderValues = [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000];

const ReservationPage = browserStorage.withFormSubmittedState("reservation")(
  ({ submittedState: editMode }) => {
    const isDesktop = useMediaQuery({ minWidth: breakpoints.large });

    const isSmallDesktop = useMediaQuery({
      minWidth: breakpoints.large,
      maxWidth: "1350px",
    });

    const isSmallMobile = useMediaQuery({
      maxWidth: breakpoints.small,
    });

    const isTablet = useMediaQuery({
      maxWidth: breakpoints.medium,
    });

    const row = isSmallDesktop || isSmallMobile || isTablet;

    const { push: pushRoute } = useHistory();

    const { displayError } = React.useContext(NotificationContext);

    const {
      updateTeam,
      currentTeam,
      loading: processing,
    } = React.useContext(SessionContext);
    const { currencySymbol } = currentTeam!;

    const initialGlobalLimit = React.useMemo(
      () => (editMode ? currentTeam?.globalLimit! : defaultGlobalLimit),
      [editMode, currentTeam?.globalLimit]
    );

    const { register, handleSubmit, setValue, watch, formState } =
      useForm<ReservationFields>({
        mode: "onTouched",
        shouldUnregister: true,
        defaultValues: {
          allowBookings: editMode ? currentTeam?.allowBookings : true,
          allowHourlyBookings: editMode
            ? currentTeam?.allowHourlyBookings
            : true,
          allowOfficeBookings: editMode
            ? currentTeam?.allowOfficeBookings
            : true,
          allowGuests: editMode ? currentTeam?.allowGuests : true,
          globalLimit: initialGlobalLimit,
        },
      });

    let initialSliderValue = React.useMemo(
      () =>
        sliderValues.includes(initialGlobalLimit)
          ? initialGlobalLimit
          : defaultGlobalLimit,
      [initialGlobalLimit]
    );

    const [sliderBookingLimit, setSliderBookingLimit] = React.useState(
      () => initialSliderValue
    );
    const [sliderFaded, setSliderFaded] = React.useState(
      () => !sliderValues.includes(initialGlobalLimit)
    );

    const onSliderMouseDown = React.useCallback(() => {
      if (sliderFaded) {
        setSliderFaded(false);
      }
    }, [sliderFaded]);

    const onSliderChange = React.useCallback(
      (value: number) => {
        setSliderBookingLimit(value);
        setValue("globalLimit", value, { shouldDirty: true });
      },
      [setValue, setSliderBookingLimit]
    );

    const allowBookingsFieldProps = register("allowBookings");
    const allowHourlyBookingsFieldProps = register("allowHourlyBookings");
    const allowOfficeBookingsFieldProps = register("allowOfficeBookings");
    const allowGuestsFieldProps = register("allowGuests");

    const { onChange: onGlobaLimitChange, ...globalLimitFieldProps } = register(
      "globalLimit",
      {
        valueAsNumber: true,
      }
    );

    const globalLimit = watch("globalLimit");

    const handleGlobalLimitChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!sliderFaded) {
          setSliderFaded(true);
        }

        onGlobaLimitChange(e);
      },
      [onGlobaLimitChange, sliderFaded]
    );

    const getDiscreteValueLabel = React.useCallback(
      (value: number, idx: number) => {
        let label;

        if (isDesktop || idx % 2 === 0) {
          label = currencySymbol + value;
        }

        return label;
      },
      [isDesktop, currencySymbol]
    );

    const onSubmit = React.useCallback(
      async ({ globalLimit, ...data }: ReservationFields) => {
        try {
          let teamFields = {
            ...data,
            globalLimit: globalLimit || sliderBookingLimit,
          };

          await updateTeam(
            teamFields,
            editMode ? "updateReservation" : "reservation"
          );
          await browserStorage.setFormSubmittedState("reservation", true);

          pushRoute(routes.customization);
        } catch (err) {
          displayError(err);
          logger.error("Error submitting Reservation page: ", err);
        }
      },
      [sliderBookingLimit, updateTeam, pushRoute, displayError, editMode]
    );

    const disabled = processing || (editMode && !formState.isDirty);

    return (
      <StyledPage.Container>
        <Helmet>
          <title>Deskpass - Teams Onboarding | Reservation Settings</title>
          <meta
            name="description"
            content="Choose reservation settings for the team account"
          />
        </Helmet>

        <StyledPage.HeaderSection>
          <StyledPage.Header>
            Configure booking settings for your team
          </StyledPage.Header>

          <StyledPage.Subheader>
            Allow your team to make the following bookings:
          </StyledPage.Subheader>
        </StyledPage.HeaderSection>

        <StyledPage.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.IconRow>
            <IconCheckbox
              {...allowBookingsFieldProps}
              label="Desk spaces for employees"
              icon={<DeskIcon />}
              row={row}
            />

            <IconCheckbox
              {...allowGuestsFieldProps}
              label="Desk spaces for guests"
              icon={<GuestsIcon />}
              row={row}
            />

            <IconCheckbox
              {...allowHourlyBookingsFieldProps}
              label="Meeting rooms"
              icon={<MeetingRoomIcon />}
              row={row}
            />

            <IconCheckbox
              {...allowOfficeBookingsFieldProps}
              label="Private office spaces"
              icon={<OfficeIcon />}
              row={row}
            />
          </Styled.IconRow>

          <StyledPage.Subheader>
            Your team members will each have a monthly booking limit of
          </StyledPage.Subheader>

          <Styled.Slider
            initialValue={initialSliderValue}
            onMouseDown={onSliderMouseDown}
            onChange={onSliderChange}
            faded={sliderFaded}
          >
            {sliderValues.map((value, idx) => (
              <DiscreteValue
                key={value}
                value={value}
                label={getDiscreteValueLabel(value, idx)}
              />
            ))}
          </Styled.Slider>

          <Styled.GlobalLimitField
            {...globalLimitFieldProps}
            onChange={handleGlobalLimitChange}
            type="number"
            prefix={!!globalLimit ? currentTeam!.currencySymbol : ""}
            placeholder="Set manually instead"
          />

          <StyledPage.FooterSection padded="6rem">
            <Button type="submit" processing={processing} disabled={disabled}>
              {editMode ? "Update" : "Submit"} Reservation Settings
            </Button>

            <SkipButton to={routes.customization} />
          </StyledPage.FooterSection>
        </StyledPage.Form>
      </StyledPage.Container>
    );
  }
);

export default ReservationPage;
